const theme = {
  lato: "'Lato', sans-serif",
  zilla: "'Zilla Slab', sans-serif",
  background: "#15171B",
  inputBackground: "#353736",
  border: "#5D646C",
  iconBackground: "#232524",
  borderbottom: "#232524",
  panel: "#353736",
  panel2: "#15171B",
  selectedTab: "#2ed085",
  selected: "#4dd68a",
  selectedText: "#4dd68a",
  hover: "#4B5562",
  hover2: "#636F80",
  text: "#efefef",
  text2: "#9FA4B5",
  dropdown: "#15171B",
  red: "#F44336",
  pink: "#E91E63",
  purple: "#9C27B0",
  deepPurple: "#673AB7",
  indigo: "#3F51B5",
  cyan: "#00BCD4",
  teal: "#009688",
  green: "#4CAF50",
  lightGreen: "#8BC34A",
  lime: "#CDDC39",
  yellow: "#FFEB3B",
  amber: "#FFC107",
  orange: "#FF9800",
  deepOrange: "#FF5722",
  brown: "#795548",
  blue: "#4bcf86",
  lightBlue: "#50dd8f",
  blueHover: "#5ddd8f",
  bluePressed: "#2dd989",
  disabled: "#222222",
  disabledText: "grey",
  deemphasized: "grey",
  toolbarText: "#9c9c9c",
  toolbar: "#343635",
  toolbar2: "#43484F",
  header: "#1b1b1b",
  white: "#fff",
  shadow15: "0px 4px 4px  rgba(0, 0, 0, 0.15)",
  shadow30: "0px 4px 4px  rgba(0, 0, 0, 0.3)",
  borderStyle: "1px solid #5D646C"
};

theme.chartColors = [
  theme.red,
  theme.pink,
  theme.purple,
  theme.deepPurple,
  theme.indigo,
  theme.blue,
  theme.lightBlue,
  theme.cyan,
  theme.teal,
  theme.green,
  theme.lightGreen,
  theme.lime,
  theme.yellow,
  theme.amber,
  theme.orange,
  theme.deepOrange,
  theme.brown
];

export default theme;
